<template>
    <div>
        <div class="intro-y box p-5 mt-5">
            <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
                <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                    <Preloader />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-name" class="form-label">
                        Имя
                        <sup v-if="v$.form.name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.name.$errors.length" for="form-name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-name"
                        v-model="form.name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-email" class="form-label">
                        Email
                        <sup v-if="v$.form.email.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.email.$errors.length" for="form-email" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.email.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-email"
                        v-model="form.email"
                        type="email"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.email.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-6 relative">
                    <label for="form-password" class="form-label">Пароль</label>
                    <input
                        id="form-password"
                        v-model="form.password"
                        type="password"
                        class="form-control w-full"
                        :disabled="!changePassword"
                        placeholder=""
                    />
                    <div v-if="!changePassword" class="change__password">
                        <a href="#" class="btn btn-secondary" @click.prevent="changePassword = !changePassword">
                            Сменить пароль
                        </a>
                    </div>
                </div>

                <div class="col-span-6 relative">
                    <label for="form-role" class="form-label">
                        Роль
                        <sup v-if="v$.form.role.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.role.$errors.length" for="form-role" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.role.$errors[0].$message) }}
                    </label>
                    <select
                        id="form-role"
                        v-model="form.role"
                        name="roles"
                        class="form-select sm:mr-2"
                        :class="{ 'form-input-error': v$.form.role.$errors.length }"
                        autocomplete="off"
                    >
                        <option v-for="(opt, optIdx) in roles" :key="optIdx" :value="opt.id">{{ opt.title }}</option>
                    </select>
                </div>

                <div class="col-span-12 flex items-center justify-end mt-5">
                    <router-link to="#" class="btn btn-secondary w-24 mb-2 mr-2" @click.prevent="redirectToMainPage">
                        Отмена
                    </router-link>
                    <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(false)">
                        Сохранить и выйти
                    </button>
                    <button type="submit" class="btn btn-primary mb-2" @click.prevent="save(true)">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { usersMixin } from '@/mixins/form/users-mixin';
import { errorResponse } from '@/mixins/form/form-mixin';
import Preloader from '@/components/preloader/Main';
import { helper as $h } from '@/utils/helper';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'Edit',
    components: { Preloader },
    mixins: [usersMixin, errorResponse, pageMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                name: { required },
                email: { required },
                role: { required },
            },
        };
    },
    data() {
        return {
            userName: null,
            changePassword: false,
        };
    },
    async created() {
        this.$store.commit('main/setPageHeader', 'Пользователи / Редактировать');
        await this.fetchUser();
        await this.fetchRoles();
        this.userName = this.form.name;
    },
    methods: {
        async save(flag) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                await this.axios.post(
                    '/users/' + this.$route.params.id,
                    { ...this.form },
                    {
                        params: {
                            _method: 'patch',
                        },
                    }
                );
                this.loading = false;

                if (flag) {
                    this.$notify('Запись сохранена');
                } else {
                    this.redirectToMainPage();
                    // await this.$router.push({ name: 'users' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('users');
        },
    },
};
</script>

<style scoped lang="scss">
.change__password {
    position: absolute;
    top: 42%;
    right: 0;
}
</style>
